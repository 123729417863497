var page_vars=getUrlVars();
var page_lng="es";

if(page_vars["lng"])
{
	page_lng=page_vars["lng"];
}else{
	if(localStorage["lau_lng"])
		page_lng=localStorage["lau_lng"];
	else{
		var language=navigator.language? navigator.language : navigator.userLanguage;
		if((language.indexOf('es') > -1) || (language.indexOf('ca')>-1))
		{
			page_lng="es";	
		}else{
			page_lng="en";	
		}
	}
}

//let magic=null;
//if(window["Magic"])
//const magic = new Magic('pk_live_15B7572DF215202F',{ locale: page_lng });

const server_url= location.hostname === "localhost" 
? "http://www.lvh.me:8080"
: "https://server.wircos.com";

function get_msg(msg)
{
	const e=document.querySelector("[aria-msg="+msg+"_"+page_lng+"]");
	if(e)
		return(e.innerHTML);
	
	return("");
}
function show_lng(lng)
{
	document.querySelectorAll(".lng").forEach(element=>{
		element.style.display = 'none';
	});
	document.querySelectorAll(".lng_"+lng).forEach(element=>{
		element.style.display = 'inline-block';
	});	
	page_lng=lng;
	localStorage["lau_lng"]=lng;
}
	
function lng_click(e)
{
	var lng=this.getAttribute("href");
	show_lng(lng);
	e.preventDefault();
}	

function getUrlVars() 
{
	var vars = {};
	var parts = window.location.href.replace(/[?&#]+([^=&#]+)=([^&#]*)/gi, function(m,key,value) 
	{
		vars[key] = value;
	});
	return(vars);
}

function formatBytes(bytes, decimals = 2) 
{
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function upload_nft(e)
{
	e.preventDefault();
    
	document.getElementById("btn_upload").classList.add("hide");
	document.getElementById("upload_error").classList.add("hide");
	document.getElementById("nft_uploading").classList.remove("hide");
	set_upload_step("progress_starting");

	const fields=document.getElementById("nft_form").querySelectorAll("[required]");
	for(let a=0;a<fields.length;a++)
	{
		if(!fields[a].validity.valid)
		{
			let error=401;
			if(fields[a].validity.valueMissing)
				error=400;
			show_upload_error(error,fields[a].getAttribute("name"));
			return;

		}
	};

	let server=server_url.replace("http","ws");
	server=server.replace("https","wss");
	
	let socket=null;
	try{
		socket = new WebSocket(server+"/"+
					//localStorage["lau_session"]+"/"+
					//localStorage["lau_user"]
					document.getElementById("e_user_email").value);
	}catch(e)
	{
		show_upload_error("connecting",null,false);
	}

	socket.addEventListener('error',function(event){
		show_upload_error("connecting",null,false);
	});

	socket.addEventListener('open', function (event) {  
	}); 

	socket.addEventListener('close', function (event) { 
	});
	
	socket.addEventListener('message', function (event) { 
		const data=JSON.parse(event.data);
		switch(data.msg)
		{
			case "error":
				show_upload_error(data.error);
				break;

			case "step":
				set_upload_step("progress_"+data.data);
				break;

			case "progress":
				set_upload_progress(data.data);
				break;

			case "start":
				start_upload_nft(e);
				break;
			
		}
		//console.log('Message from server ', event.data); 
	});
}

function show_upload_error(error,info=null,code=true)
{
	let error_msg=get_msg("error_"+error);
	if(info)
	{
		let parent=document.getElementById("e_"+info).parentElement;
		if(!parent.querySelector("label")) parent=parent.parentElement;
 			error_msg+=": "+parent.querySelector("label").innerText;
	}
	if(code)
		error_msg+="<br>Error: "+error;
					
	document.getElementById("upload_error").innerHTML=error_msg;	
	document.getElementById("upload_error").classList.remove("hide");	
							
	document.getElementById("btn_upload").classList.remove("hide");	
	document.getElementById("nft_uploading").classList.add("hide");		
}

function set_upload_step(step)
{
	document.getElementById("uploading_step").innerHTML=get_msg(step);
	document.getElementById("uploading_progress").value=0;
}
function set_upload_progress(progress)
{
	document.getElementById("uploading_progress").style.width=progress+"%";
	document.getElementById("uploading_value").innerText=progress+"%";
}

function start_upload_nft(e)
{
	//e.preventDefault();

	let nft_form=document.getElementById("nft_form");
    let data = new FormData(nft_form);

	data.append("user_lng",page_lng);

	//document.getElementById("upload_error").classList.add("hide");
	//document.getElementById("nft_uploading").classList.remove("hide");
	//document.getElementById("btn_upload").classList.add("hide");
	document.querySelectorAll(".upload_error_type").forEach(element=>{
			element.classList.add("hide");
	});

	let request = new XMLHttpRequest();
	request.open('POST', server_url+'/upload_nft'); 

	request.upload.addEventListener('progress', function(e) {
		//let percent_completed = parseInt((e.loaded / e.total)*100)+"%";
		//console.log(e.loaded,e.total,percent_completed);
		set_upload_progress(parseInt((e.loaded / e.total)*100));
	});

	request.addEventListener('load', function(e) {
		if (request.status >= 200 && request.status <= 299)
		{
				const response=JSON.parse(request.response);
				document.getElementById("nft_uploading").classList.add("hide");
			
                let result;
                if(response.status)
                {   
					//update_new_info(response.data.nft_id,true); //response.data.nft_id,true);
					if(document.getElementById("nfts_grid"))
						load_nfts("nfts_grid",localStorage["lau_user"],"nfts_grid_empty");	
					document.getElementById("nft_checkout_link").setAttribute("href",response.data.nft_link);
					document.getElementById("nft_checkout_link").innerHTML=response.data.nft_link;
					document.getElementById("nft_checkout_url").setAttribute("href",response.data.nft_url);
					document.getElementById("nft_checkout_url").innerHTML=response.data.nft_url;
					document.querySelector(".main_form").classList.add("hide");
        			document.getElementById("upload_success").classList.remove("hide");
		        }else{
					show_upload_error(response.error,response.info);
                }
		
		}else{
			modal_show("Error","Error upload","error");
		}
	});

	request.send(data);
}

async function load_nfts(where,email,empty)
{
	document.getElementById(empty).classList.add("hide");

	const res=await fetch(server_url+"/nfts/"+email);
	let data = await res.json();
	if(!data) return;
	if(!data.nfts) return;

	show_nfts(where,data.nfts,empty);
	show_stats(data.stats);
}
function show_stats(stats)
{
	if(stats["plan"])
	{
		document.getElementById("account_stats_plan_name").innerText=stats["plan"]["name"];
		if(stats["plan"]["nfts"]==0)
			document.getElementById("account_stats_plan_info_nfts").innerText=get_msg("stats_unlimited");
		else
			document.getElementById("account_stats_plan_info_nfts").innerText=stats["plan"]["nfts"];
		if(!stats["plan"]["space"]["num"])
			document.getElementById("account_stats_plan_info_space").innerText=get_msg("stats_unlimited");
		else
			document.getElementById("account_stats_plan_info_space").innerText=stats["plan"]["space"]["num"]+" "+
																	  stats["plan"]["space"]["type"];
	}
	document.getElementById("account_stats_nfts").innerText=stats.count;
	document.getElementById("account_stats_size").innerText=stats["size"]["num"];
	document.getElementById("account_stats_size_type").innerText=stats["size"]["type"];
}
function monument_functions(name,params)
{
	let functions={
		account_nft_click:async function(params)
		{
			const res = await fetch(server_url+"/get_nft", 
			{
				method: 'POST',
				headers: {
			  		'Accept': 'application/json',
			  		'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					account_email: document.getElementById("account_email").value,
					account_key: document.getElementById("account_key").value,
					nft_id:params.nft_id,
				})
			  });			  
			let nft = await res.json();
			if(!nft){ alert("error"); return;}
			if(!nft.status){alert("error: "+nft.error); return};

			document.getElementById("e_nft_id").value=nft.data.nft_id;
			document.getElementById("e_nft_title").value=nft.data.nft_title;
			document.getElementById("e_nft_description").value=nft.data.nft_description;
			if(nft.data.nft_information)
				document.getElementById("e_nft_information").value=nft.data.nft_information;
			else 
				document.getElementById("e_nft_information").value="";
			document.getElementById("e_nft_price").value=nft.data.nft_price;
			
			if(nft.data.nft_private) 
				document.getElementById("e_nft_private").value=nft.data.nft_private;
			else
				document.getElementById("e_nft_private").value="";
			document.getElementById("nft_thumb_image").setAttribute("src",nft.data.nft_thumbnail_url);
			document.getElementById("nft_thumb_image").classList.remove("hide");

			update_new_info(nft.data.nft_id);
			change_tab("create");

			//let changeEvent = new Event('change');
			//document.getElementById("my_item_mode").dispatchEvent(changeEvent);
		}
	}
	functions[name](params);
}
function account_nft_click(data)
{
	alert(data);
}
function show_nfts(parent,data,empty)
{
	let grid=document.getElementById(parent);
	grid.innerHTML="";

	if((data==null) || (data.length==0))
	{
		grid.classList.add("hide");
		document.getElementById(empty).classList.remove("hide");
		return;
	}

	grid.classList.remove("hide");

	data.forEach(function(nft) {
		let item=document.querySelector('.elements .nft_item').cloneNode(true);
		item.setAttribute("id","nft_"+nft.nft_id);
		item.setAttribute("aria-status",nft.nft_status);
		
		item.querySelector(".nft_thumbnail").setAttribute('src', nft.nft_thumbnail_url);
		let info=item.querySelector('.nft_info');
		info.querySelector(".nft_cost").append(nft.nft_price.toLocaleString("es-US"));
		
		info.querySelector(".nft_views").append(nft.nft_views);
		item.querySelector(".nft_title").append(nft.nft_title);
		info.querySelector(".nft_description").append(nft.nft_description);

		item.addEventListener("click",function(e)
		{
			e.preventDefault();
			show_nft_options(nft.nft_id,
							item.querySelector(".nft_thumbnail").getAttribute('src'),
							nft.nft_title,
							info.querySelector(".nft_cost").innerHTML,
							item.getAttribute("aria-status")+page_lng,
							nft.nft_checkout, nft.nft_url
							);
		});

		grid.append(item);
	});
}

function change_section(element)
{
	if(document.querySelector(".account_content.active"))
	{
		document.querySelector(".account_content.active").classList.add("hide");
		document.querySelector(".account_content.active").classList.remove("active");
	}
			
	let content=element.getAttribute("aria-section");
	if(document.getElementById(content))
	{
		document.getElementById(content).classList.add("active");
		document.getElementById(content).classList.remove("hide");
	}	
	if(document.querySelector(".account_link.active"))
		document.querySelector(".account_link.active").classList.remove("active");
	element.classList.add("active");
	window.scrollTo({
		top: 0,
		behavior: "smooth"
	});
}

function load()
{
	show_lng(page_lng)
	document.querySelectorAll(".btn_lng").forEach(element=>{
		element.addEventListener('click', lng_click,false);
	});
	
	

	if(document.getElementById("header"))
		document.getElementById("header").classList.remove("header_hide");

	document.querySelectorAll("#nav_menu a").forEach(element=>{
			element.addEventListener('click', function()
			{
				document.getElementById("side-menu").checked=false; 
			},false);
		});
	
	if(document.getElementById("btn_cookies"))
	{
		if(!localStorage["lau_cookies_accept"])
		{
			document.getElementById("cookies").classList.remove("hide");
			document.getElementById("btn_cookies").addEventListener("click",function(e)
			{
				e.preventDefault();
				document.getElementById("cookies").classList.add("hide");
				localStorage["lau_cookies_accept"]="yes";
			});
		}
	}
	//if(page_vars["test"])
	//{
			document.querySelectorAll(".test").forEach(element=>{
				element.classList.remove("hide");
			});
			
			document.querySelectorAll(".no_test").forEach(element=>{
				element.classList.add("hide");
			});
			
			document.querySelectorAll(".disabled").forEach(element=>{
				element.classList.remove("disabled");
			});		
	//}

	document.querySelectorAll(".faq_question").forEach(element=>{
		element.addEventListener('click', function(e)
		{			
			e.preventDefault();
			this.classList.toggle("faq_arrow_extend");
			this.nextElementSibling.classList.toggle("faq_answer_visible");
		},false);
	});

	
	
	if(document.getElementById("account_page"))
	{
		document.querySelector(".login_error").classList.add("hide");
		document.querySelector(".login_wait").classList.add("hide");

		const section=window.location.href.split("#");
		if(section.length>1)
		{
			const element=document.querySelector('[aria-section="'+section[1]+'"]');
			if(element) change_section(element);
		}
			
		document.getElementById("btn_transactions").addEventListener('click',function(e)
		{
			e.preventDefault();
		},false);

		document.querySelectorAll(".account_option").forEach(element=>{
			element.addEventListener('click', function(e)
			{	
				e.preventDefault();
						
				if(!element.classList.contains("active"))
				//if(document.getElementById("account_page"))
				{
					document.querySelector(".option_nft_edit").classList.add("hide");
					change_section(element);
				}		
			},false);

		});
	
		document.getElementById("btn_login").addEventListener('click',handleLogin);

		if(localStorage["lau_session"])
		{
			test_token();
		}else{
			if(page_vars["token"])
			{
				if(localStorage["lau_user"])
				{
					validate_token(page_vars["token"],function(status,data)
					{
						if(status)
						{ 
							localStorage["lau_session"]=data;
							test_token();
						}else{
							show_logged(false);
						}
					});
				}else{
					show_logged(false);
				}
			}else{
				show_logged(false);
			}
		}
	}else{
		if(localStorage["lau_session"])
		{
			//if(document.getElementById("e_user_email"))
			//	document.getElementById("e_user_email").value=localStorage["lau_user"];
			show_logged(true);
		}else{
			show_logged(false);
		}
	}
	document.getElementById("btn_logout").addEventListener('click',handleLogout);

	document.querySelectorAll(".tooltip").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			let text=element.parentElement.querySelector(".tooltipvalue").innerText;
			navigator.clipboard.writeText(text);
			element.parentElement.querySelector(".tooltiptext_init").classList.add("hide");
			element.parentElement.querySelector(".tooltiptext_result").classList.remove("hide");	
		});
		element.addEventListener('mouseout', function(e)
		{
			e.preventDefault();
			element.parentElement.querySelector(".tooltiptext_result").classList.add("hide");
			element.parentElement.querySelector(".tooltiptext_init").classList.remove("hide");
		});
	});

	if(document.getElementById("btn_hide_nft_popup"))
	{
		document.getElementById("btn_hide_nft_popup").addEventListener("click",function(e)
		{
			e.preventDefault();
			hide_nft_options();
		});

		document.getElementById("btn_nft_destroy").addEventListener('click', function(e)
		{
			e.preventDefault();
			
			const element=document.querySelector(".nft_popup");
			const nft_id=element.getAttribute("aria-nft");
			hide_nft_options();

			modal_show(get_msg("delete_title"),get_msg("delete")+"<i>"+element.getAttribute("aria-title")+"</i>",
					"input",function(res)
			{
				if((res) && (res==get_msg("delete_word")))
				{
					let data={"email":localStorage["lau_user"],"lng":page_lng,
								"token":localStorage["lau_session"],"nft":nft_id};
					fetch(server_url+"/delete_nft",{
						method: "POST",
						headers: {"Access-Control-Allow-Origin": "*",
								   "Content-Type": "application/json"},
						body: JSON.stringify(data), 
					}).then((response)=>{
						if (response.status >= 200 && response.status <= 299) 
							return response.json();
						throw Error(response.statusText);
					}).then((response)=>{
						if(response.status)
						{   
							document.getElementById("nft_"+nft_id).remove();
							show_stats(response);
							
							modal_show(get_msg("delete_title"),get_msg("delete_ok"),"info",function(res)
							{
								if(response.count==0)
									show_nfts("nfts_grid",null,"nfts_grid_empty");
							});
						}else{
							modal_show(get_msg("delete_title"),get_msg("delete_ko")+
									get_msg("error_"+response.error)+" ("+response.error+").","error");
						}
					});
				}
			});
				
		},false);

		document.getElementById("btn_nft_update").addEventListener('click', function(e)
		{
			e.preventDefault();
			
			const element=document.querySelector(".nft_popup");
			const nft_id=element.getAttribute("aria-nft");
			hide_nft_options();

			let data={"email":localStorage["lau_user"],"lng":page_lng,
						"token":localStorage["lau_session"],"nft":nft_id,
						"price":document.getElementById("nft_edit_price").value,
						"status":document.getElementById("nft_edit_status").value.charAt(0)};
			fetch(server_url+"/update_nft",{
				method: "POST",
				headers: {"Access-Control-Allow-Origin": "*",
							"Content-Type": "application/json"},
				body: JSON.stringify(data), 
			}).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
					return response.json();
				throw Error(response.statusText);
			}).then((response)=>{
				if(response.status)
				{   
					document.getElementById("nft_"+nft_id).setAttribute("aria-status",data.status);
					document.getElementById("nft_"+nft_id).querySelector(".nft_cost").innerText=data.price;
							
					modal_show(get_msg("update_title"),get_msg("update_ok"),"info",function(res)
					{
						// change nft id and status
					});
				}else{
					modal_show(get_msg("update_title"),get_msg("update_ko")+
							get_msg("error_"+response.error)+" ("+response.error+").","error");
				}
			});
		},false);

		document.getElementById("nft_edit_view").addEventListener("click",function(e)
		{
			e.preventDefault();
			const form=document.getElementById("nft_access_form");
			form.setAttribute("action",this.getAttribute("href"));
			form.querySelector("[name=nft_access_lng]").value=page_lng;
			form.querySelector("[name=nft_access_user]").value=localStorage["lau_user"];
			form.querySelector("[name=nft_access_session]").value=localStorage["lau_session"];

			form.submit();

		},false);
	}
}

function show_logged(show)
{
	if(show)
	{
		document.querySelectorAll(".logged").forEach(element=>{
			element.classList.remove("hide");
		});
		document.querySelectorAll(".no_logged").forEach(element=>{
			element.classList.add("hide");
		});
		if(document.getElementById("e_user_email"))
			document.getElementById("e_user_email").value=localStorage["lau_user"];
	}else{
		document.querySelectorAll(".logged").forEach(element=>{
			element.classList.add("hide");
		});
		document.querySelectorAll(".no_logged").forEach(element=>{
			element.classList.remove("hide");
		});
	}
}

function test_token()
{
	verify_token(localStorage["lau_session"],localStorage["lau_user"],function(status,data)
		{
			if(status)
			{ 
				show_logged(true);
				
				document.getElementById("account_email").value=data.user["user_email"];
				document.getElementById("account_key").value=data.user["user_key"];
				document.getElementById("account_name").value=data.user["user_name"];

				document.getElementById("account_bio").value=data.user["user_bio"];
				show_stats(data.stats);
				show_nfts("nfts_grid",data.nfts,"nfts_grid_empty");
				
			}else{
				show_logged(false);
				localStorage.removeItem("lau_session");
				//alert("Token verify error:"+data);	// OKO
			}
	});
}

async function validate_token(token,callback)
{
	const res=await fetch(server_url+"/auth/validate/"+page_lng+"/"+token);
	let data = await res.json();
	if(!data) 
	{
		callback(false,"server_error");
		return;
	}
	if(!data.status)
	{
		callback(false,data.error);
		return;
	}
	callback(true,data.new_token);
}

async function verify_token(token,email,callback)
{
	const res=await fetch(server_url+"/auth/verify/"+email+"/"+token+"/"+page_lng);
	let data = await res.json();
	if(!data) 
	{
		callback(false,"server_error");
		return;
	}
	if(!data.status)
	{
		callback(false,data.error);
		return;
	}
	callback(true,data);
}

/*
async function load_user(token)
{
	const res=await fetch(server_url+"/get_user/"+token);
	let user = await res.json();
	if(!user) return(false);
	if(!user.status)
	{
		alert(user.error);
		return(false);
	}
	document.getElementById("account_email").value=user.user_data["user_email"];
	document.getElementById("account_key").value=user.user_data["user_key"];
	document.getElementById("account_name").value=user.user_data["user_name"];
	alert("pasa");
	document.getElementById("account_bio").value=user.user_data["user_bio"];
	document.getElementByIf("account_stats_nfts").value=user.user_data["user_stats_nfts"];
	document.getElementByIf("account_stats_space").value=user.user_data["user_stats_space"];
	document.getElementByIf("account_stats_money").value=user.user_data["user_stats_money"];

	show_nfts("grid",user.nfts);
	return(true);
}
*/

function wait_for_session()
{
	if(localStorage["lau_session"])
	{
		document.querySelector(".login_wait").classList.add("hide");
		document.querySelector(".login_close_window").classList.remove("hide");
	}else{
		setTimeout(wait_for_session,2000);
	}
}

function handleLogin(e)
{
    e.preventDefault();

	document.querySelector(".login_error").classList.add("hide");
	let data={"email":document.getElementById("login_email").value,"lng":page_lng,"server":server_url};
	if(data["email"]=="") return;
	
	if(this.classList.contains("btn_disabled")) return;

	this.classList.add("btn_disabled");
	fetch(server_url+"/auth/magiclink",{
                method: "POST",
                headers: {
					"Access-Control-Allow-Origin": "*",
					'Content-Type': 'application/json'},
                body: JSON.stringify(data), 
            }).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
				{
		            return response.json();
				}else{
					this.classList.remove("btn_disabled");
					document.querySelector(".login_error").classList.remove("hide");
				}
	        }).then((response)=>{
				if(response.status)
				{
					document.querySelector(".login_form").classList.add("hide");
					document.querySelector(".login_wait").classList.remove("hide");
					localStorage["lau_user"]=document.getElementById("login_email").value;

					// comprobar que se está la sesión
					wait_for_session();

				}else{
					this.classList.remove("btn_disabled");
					document.querySelector(".login_error").classList.remove("hide");
				}
			}).catch((error)=>{
				this.classList.remove("btn_disabled");
				document.querySelector(".login_error").classList.remove("hide");
			}
		);
}

const handleLogout = async (e) => {
	e.preventDefault();
	show_logged(false);
	localStorage.removeItem("lau_session");
	localStorage.removeItem("lau_useremail");
};

function show_nft_options(nft,thumb,title,price,status,checkout,url)
{
	const popup=document.querySelector(".nft_popup");
	if(nft==popup.getAttribute("aria-nft"))
	{
		hide_nft_options();
		return;
	}
	popup.setAttribute("aria-nft",nft);
	popup.setAttribute("aria-title",title);
	popup.querySelector(".nft_popup_image").setAttribute("src",thumb);
	document.getElementById("nft_edit_title").innerHTML=title;
	document.getElementById("nft_edit_price").value=price;
	document.getElementById("nft_edit_status").value=status;
	//document.getElementById("nft_edit_checkout").innerText=checkout;
	document.getElementById("nft_edit_checkout").setAttribute("href",checkout);
	//document.getElementById("nft_edit_view").innerText=url;
	document.getElementById("nft_edit_view").setAttribute("href",url);
	popup.classList.remove("nft_popup_hide");
}

function hide_nft_options()
{
	const popup=document.querySelector(".nft_popup");
	popup.classList.add("nft_popup_hide");	
	popup.setAttribute("aria-nft","0");
}

document.addEventListener("DOMContentLoaded", load, false);